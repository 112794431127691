export const ADDRESS = Object.freeze({
  ADDRESS: "address",
  ADDRESS_LINE_4: "addressLine4",
  ADDRESS_NICKNAME: "addressNickname",
  ADDRESS_TYPE: "addressType",
  APARTMENT: "apartment",
  APARTMENT_NAME: "apartmentName",
  APARTMENT_NUMBER: "apartmentNumber",
  BLOCK: "block",
  BUILDING: "building",
  BUILDING_NAME: "buildingName",
  CITY: "city",
  COMPLEX: "complex",
  COMPLEX_NAME: "complexName",
  COMPLEX_NUMBER: "complexNumber",
  FLAT: "flat",
  HOTEL: "hotel",
  HOTEL_NAME: "hotelName",
  HOUSE: "house",
  INSTRUCTION: "instruction",
  OFFICE: "Office",
  LANDED: "landed",
  LEVEL: "level",
  NEIGHBORHOOD: "neighborhood",
  NEIGHBORHOOD_KE: "neighborhood_ke",
  OFFICE_NAME: "organizationName",
  ORGANIZATION_NAME: "organizationName",
  PLACE: "place",
  PLACE_NAME: "placeName",
  POSTAL_CODE: "postalCode",
  REGION: "region",
  ROOM_NUMBER: "roomNumber",
  SECTOR: "addressLine4",
  STREET_NAME: "streetName",
  STREET_NUMBER: "streetNumber",
  SUITE_NUMBER: "unitNumber",
  UNIT: "unit",
  UNIT_INFO: "unitInfo",
  UNIT_NUMBER: "unitNumber",
  LATITUDE: "latitude",
  LOCATION_NAME: "locationName",
  LONGITUDE: "longitude",
  DELIVERY_INSTRUCTIONS: "deliveryInstructions",
});

export const ADDRESS_FIELDS = Object.freeze({
  [ADDRESS.ADDRESS]: {
    key: ADDRESS.ADDRESS,
    label: "customer:locations.address.address",
    mapping: ADDRESS.ADDRESS,
  },
  [ADDRESS.ADDRESS_NICKNAME]: {
    key: ADDRESS.ADDRESS_NICKNAME,
    label: "customer:locations.address.address_nickname",
    mapping: ADDRESS.ADDRESS_NICKNAME,
  },
  [ADDRESS.ADDRESS_LINE_4]: {
    key: ADDRESS.ADDRESS_LINE_4,
    label: "customer:locations.address.address_line_4",
    mapping: ADDRESS.ADDRESS_LINE_4,
  },
  [ADDRESS.ADDRESS_TYPE]: {
    key: ADDRESS.ADDRESS_TYPE,
    label: "customer:locations.address.address_type",
    mapping: ADDRESS.ADDRESS_TYPE,
  },
  [ADDRESS.APARTMENT]: {
    key: ADDRESS.APARTMENT,
    label: "customer:locations.address.apartment",
    mapping: ADDRESS.APARTMENT,
  },
  [ADDRESS.OFFICE]: {
    key: ADDRESS.OFFICE,
    label: "customer:locations.address.office",
    mapping: ADDRESS.OFFICE,
  },
  [ADDRESS.APARTMENT_NAME]: {
    key: ADDRESS.APARTMENT_NAME,
    label: "customer:locations.address.apartment_name",
    mapping: ADDRESS.ORGANIZATION_NAME,
  },
  [ADDRESS.APARTMENT_NUMBER]: {
    key: ADDRESS.APARTMENT_NUMBER,
    label: "customer:locations.address.apartment_number",
    mapping: ADDRESS.UNIT_NUMBER,
  },
  [ADDRESS.BLOCK]: {
    key: ADDRESS.BLOCK,
    label: "customer:locations.address.block",
    mapping: ADDRESS.BLOCK,
  },
  [ADDRESS.BUILDING]: {
    key: ADDRESS.BUILDING,
    label: "customer:locations.address.building",
    mapping: ADDRESS.BUILDING,
  },
  [ADDRESS.BUILDING_NAME]: {
    key: ADDRESS.BUILDING_NAME,
    label: "customer:locations.address.building_name",
    mapping: ADDRESS.ORGANIZATION_NAME,
  },
  [ADDRESS.CITY]: {
    key: ADDRESS.CITY,
    label: "customer:locations.address.city",
    mapping: ADDRESS.CITY,
  },
  [ADDRESS.COMPLEX]: {
    key: ADDRESS.COMPLEX,
    label: "customer:locations.address.complex",
    mapping: ADDRESS.COMPLEX,
  },
  [ADDRESS.COMPLEX_NAME]: {
    key: ADDRESS.COMPLEX_NAME,
    label: "customer:locations.address.complex_name",
    mapping: ADDRESS.COMPLEX_NAME,
  },
  [ADDRESS.COMPLEX_NUMBER]: {
    key: ADDRESS.COMPLEX_NUMBER,
    label: "customer:locations.address.complex_number",
    mapping: ADDRESS.COMPLEX_NUMBER,
  },
  [ADDRESS.FLAT]: {
    key: ADDRESS.FLAT,
    label: "customer:locations.address.flat",
    mapping: ADDRESS.FLAT,
  },
  [ADDRESS.HOTEL]: {
    key: ADDRESS.HOTEL,
    label: "customer:locations.address.hotel",
    mapping: ADDRESS.HOTEL,
  },
  [ADDRESS.HOTEL_NAME]: {
    key: ADDRESS.HOTEL_NAME,
    label: "customer:locations.address.hotel_name",
    mapping: ADDRESS.ORGANIZATION_NAME,
  },
  [ADDRESS.HOUSE]: {
    key: ADDRESS.HOUSE,
    label: "customer:locations.address.house",
    mapping: ADDRESS.HOUSE,
  },
  [ADDRESS.INSTRUCTION]: {
    key: ADDRESS.INSTRUCTION,
    label: "customer:locations.address.instruction",
    mapping: ADDRESS.INSTRUCTION,
  },
  [ADDRESS.DELIVERY_INSTRUCTIONS]: {
    key: ADDRESS.DELIVERY_INSTRUCTIONS,
    label: "customer:locations.address.instruction",
    mapping: ADDRESS.DELIVERY_INSTRUCTIONS,
  },
  [ADDRESS.LANDED]: {
    key: ADDRESS.LANDED,
    label: "customer:locations.address.landed",
    mapping: ADDRESS.LANDED,
  },
  [ADDRESS.LEVEL]: {
    key: ADDRESS.LEVEL,
    label: "customer:locations.address.level",
    mapping: ADDRESS.LEVEL,
  },
  [ADDRESS.LOCATION_NAME]: {
    key: ADDRESS.LOCATION_NAME,
    label: "customer:locations.address.location_name",
    mapping: ADDRESS.LOCATION_NAME,
  },
  [ADDRESS.NEIGHBORHOOD]: {
    key: ADDRESS.NEIGHBORHOOD,
    label: "customer:locations.address.neighborhood",
    mapping: ADDRESS.NEIGHBORHOOD,
  },
  [ADDRESS.NEIGHBORHOOD_KE]: {
    key: ADDRESS.NEIGHBORHOOD,
    label: "customer:locations.address.neighborhood",
    mapping: ADDRESS.CITY,
  },
  [ADDRESS.ORGANIZATION_NAME]: {
    key: ADDRESS.ORGANIZATION_NAME,
    label: "customer:locations.address.organization_name",
    mapping: ADDRESS.ORGANIZATION_NAME,
  },
  [ADDRESS.OFFICE_NAME]: {
    key: ADDRESS.OFFICE_NAME,
    label: "customer:locations.address.office_name",
    mapping: ADDRESS.ORGANIZATION_NAME,
  },
  [ADDRESS.PLACE]: {
    key: ADDRESS.PLACE,
    label: "customer:locations.address.place",
    mapping: ADDRESS.PLACE,
  },
  [ADDRESS.PLACE_NAME]: {
    key: ADDRESS.PLACE_NAME,
    label: "customer:locations.address.place_name",
    mapping: ADDRESS.ORGANIZATION_NAME,
  },
  [ADDRESS.POSTAL_CODE]: {
    key: ADDRESS.POSTAL_CODE,
    label: "customer:locations.address.postal_code",
    mapping: ADDRESS.POSTAL_CODE,
  },
  [ADDRESS.REGION]: {
    key: ADDRESS.REGION,
    label: "customer:locations.address.region",
    mapping: ADDRESS.REGION,
  },
  [ADDRESS.ROOM_NUMBER]: {
    key: ADDRESS.ROOM_NUMBER,
    label: "customer:locations.address.room_number",
    mapping: ADDRESS.UNIT_NUMBER,
  },
  [ADDRESS.STREET_NAME]: {
    key: ADDRESS.STREET_NAME,
    label: "customer:locations.address.street_name",
    mapping: ADDRESS.STREET_NAME,
  },
  [ADDRESS.STREET_NUMBER]: {
    key: ADDRESS.STREET_NUMBER,
    label: "customer:locations.address.street_number",
    mapping: ADDRESS.STREET_NUMBER,
  },
  [ADDRESS.UNIT]: {
    key: ADDRESS.UNIT,
    label: "customer:locations.address.unit",
    mapping: ADDRESS.UNIT,
  },
  [ADDRESS.UNIT_INFO]: {
    key: ADDRESS.UNIT_INFO,
    label: "customer:locations.address.unit_number",
    mapping: ADDRESS.UNIT_INFO,
  },
  [ADDRESS.UNIT_NUMBER]: {
    key: ADDRESS.UNIT_NUMBER,
    label: "customer:locations.address.unit_number",
    mapping: ADDRESS.UNIT_NUMBER,
  },
  [ADDRESS.SUITE_NUMBER]: {
    key: ADDRESS.SUITE_NUMBER,
    label: "customer:locations.address.suite_number",
    mapping: ADDRESS.SUITE_NUMBER,
  },
  [ADDRESS.STATE]: {
    key: ADDRESS.STATE,
    label: "customer:locations.address.state",
    mapping: ADDRESS.STATE,
  },
  [ADDRESS.LATITUDE]: {
    key: ADDRESS.LATITUDE,
    label: "customer:locations.address.latitude",
    mapping: ADDRESS.LATITUDE,
  },
  [ADDRESS.LONGITUDE]: {
    key: ADDRESS.LONGITUDE,
    label: "customer:locations.address.longitude",
    mapping: ADDRESS.LONGITUDE,
  },
});
