import React, { Fragment, memo } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getMarketConfigs } from "ducks/market/selectors";

import { orderHistorySubheadings } from "constants/customer";

import OrderDetails from "../OrderDetails";
import OrderLineItems from "../OrderLineItems";

const HistoricalOrder = memo(function HistoricalOrder(props) {
  const { isCallCenterEnabled, orderLink, reorder, storeOrderId, t } = props;
  const { IS_FILTER_CALL_CENTER_ENABLED } = useSelector(getMarketConfigs);
  const isReorderDisabled =
    IS_FILTER_CALL_CENTER_ENABLED && !isCallCenterEnabled;
  const handleReorderClick = () => {
    reorder(orderLink);
  };

  return (
    <Fragment>
      <div className="grid order">
        <OrderLineItems {...props} />

        <OrderDetails headings={orderHistorySubheadings} {...props} />
      </div>

      <button
        className="btn btn--primary reorder"
        data-quid={`reorder-${storeOrderId}`}
        onClick={handleReorderClick}
        type="button"
        disabled={isReorderDisabled}
      >
        {t("customer:orders:order_history:reorder")}
      </button>
    </Fragment>
  );
});

HistoricalOrder.propTypes = {
  isCallCenterEnabled: PropTypes.bool.isRequired,
  orderLink: PropTypes.string.isRequired,
  reorder: PropTypes.func.isRequired,
  storeOrderId: PropTypes.string.isRequired,
};

export default HistoricalOrder;
