import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, mergeMap, pluck } from "rxjs/operators";

import { RESET_STATE } from "ducks/configuration";

import { setHasUserSelectedStore } from "rtk_redux/slices/customerPageSlice";

import withValidation from "modules/elValidadore";
import { idValidators, urlValidators } from "modules/validators";

const SCOPE = "order-entry/store/";

export const GET_STORE = `${SCOPE}GET_STORE`;
export const GET_STORE_ERROR = `${SCOPE}GET_STORE_ERROR`;
export const SET_STORE = `${SCOPE}SET_STORE`;

export const getStore = withValidation(
  ({
    orderId = null,
    orderStoreLink = "",
    selected = true,
    setAsActive = false,
    setUserSelected = false,
    url = "",
  } = {}) => ({
    type: GET_STORE,
    orderId,
    orderStoreLink,
    selected,
    setAsActive,
    setUserSelected,
    url,
  }),
  {
    url: urlValidators,
  }
);

export function getStoreError(error) {
  return {
    type: GET_STORE_ERROR,
    error,
  };
}

export const setStore = withValidation(
  ({
    acceptableCreditCards = [],
    acceptablePaymentTypes = [],
    acceptableTipPaymentTypes = [],
    acceptAnonymousCreditCards = false,
    active = false,
    addressDescription = "",
    addressId = null,
    carryoutWaitTimeReason = null,
    carsideTippingEnabled = false,
    cashLimit = Infinity,
    city = "",
    deliveryWaitTimeReason = null,
    hoursDescription = "",
    links = {},
    isCallCenterEnabled = true,
    isOnlineNow = false,
    isOpen = false,
    isTippingAllowedAtCheckout = false,
    orderId = null,
    orderStoreLink = "",
    phone = "",
    postalCode = "",
    preferredCurrency = "",
    preferredLanguage = "",
    region = "",
    relationships = {},
    selected = false,
    serviceHours = {},
    serviceMethodEstimatedWaitMinutes = {},
    serviceMethods = [],
    storeCoordinates = {},
    storeId = null,
    storeName = "",
    streetName = "",
    timeZoneOffsetMinutes = 0,
  } = {}) => ({
    type: SET_STORE,
    acceptableCreditCards,
    acceptablePaymentTypes,
    acceptableTipPaymentTypes,
    acceptAnonymousCreditCards,
    active,
    addressDescription,
    addressId,
    cashLimit,
    carryoutWaitTimeReason,
    carsideTippingEnabled,
    city,
    deliveryWaitTimeReason,
    hoursDescription,
    links,
    isCallCenterEnabled,
    isOnlineNow,
    isOpen,
    isTippingAllowedAtCheckout,
    orderId,
    orderStoreLink,
    phone,
    postalCode,
    preferredCurrency,
    preferredLanguage,
    region,
    relationships,
    selected,
    serviceHours,
    serviceMethodEstimatedWaitMinutes,
    serviceMethods,
    storeCoordinates,
    storeId,
    storeName,
    streetName,
    timeZoneOffsetMinutes,
  }),
  {
    storeId: idValidators,
  }
);

export const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_STORE:
      return Object.assign(
        {},
        action.active
          ? Object.values(state).reduce(
              (all, { storeId, ...store }) =>
                Object.assign(all, {
                  [storeId]: Object.assign({}, store, {
                    active: false,
                    storeId,
                  }),
                }),
              {}
            )
          : state,
        {
          [action.storeId]: action,
        }
      );
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export const getStoreEpic = (action$, _, { fetch }) => {
  return action$.pipe(
    ofType(GET_STORE),
    mergeMap(
      ({
        orderAddressLink,
        orderId,
        orderStoreLink,
        selected,
        setAsActive: active,
        setUserSelected,
        ...action
      }) =>
        fetch(action).pipe(
          pluck("response", "data"),
          mergeMap(({ attributes, id, links, relationships }) =>
            [
              setStore(
                Object.assign({}, attributes, {
                  active,
                  links,
                  orderAddressLink,
                  orderId,
                  orderStoreLink,
                  relationships,
                  selected,
                  storeId: id,
                })
              ),
            ].concat(setUserSelected ? setHasUserSelectedStore(true) : [])
          ),
          catchError((error) => of(getStoreError(error)))
        )
    )
  );
};

export const epics = combineEpics(getStoreEpic);
