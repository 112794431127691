import { createSelector, defaultMemoize } from "reselect";

import { formatComplexLine, getAddressString } from "ducks/address/helpers";
import {
  getAddressLine1,
  getAddressLine2,
  getAddresses,
  getDeliveryInstructions,
  getStreetAddress,
} from "ducks/address/selectors";
import { getMarketConfigs } from "ducks/market/selectors";
import {
  getActiveArea,
  getBases,
  getCheeseToppings,
  getCouponById,
  getCoupons,
  getProducts,
  getSides,
  getSizes,
  getToppings,
  getWeightedOptions,
} from "ducks/menu/selectors";
import { getOrderCoupons } from "ducks/orderCoupon/selectors";
import { getAvailableServiceMethods, getStores } from "ducks/store/selectors";
import { getVariantCodeFromProduct } from "ducks/variants/selectors";
import { getSelfLink } from "selectors/related";
import getResult from "selectors/result";
import getState from "selectors/state";

import { PIZZA_CATEGORY } from "constants/menu";
import {
  AREAS,
  OPTION,
  ORDER_COMPLETION_STATUS,
  ORDER_TIMING,
  SERVICE_METHOD,
  defaultArea,
} from "constants/order";
import { ONLINE_STATUS, STORE_STATUS } from "constants/store";
import { WEIGHT } from "constants/topping";
import exists from "modules/exists";
import { formatDateTime } from "modules/formatDateTime";
import isDelivery from "modules/isDelivery";
import sortByProp from "modules/sortByProp";

export const getOrders = ({ order = {} } = {}) => order;

export const getOrderProducts = ({ orderProduct = {} } = {}) => orderProduct;

export const getParts = ({ part = {} } = {}) => part;

export const getPartOptions = ({ partOption = {} } = {}) => partOption;

const filterByFulfilled = ({ orderStatus }) =>
  orderStatus === ORDER_COMPLETION_STATUS.FULFILLED;

const filterByUnfulfilled = ({ orderStatus }) =>
  orderStatus !== ORDER_COMPLETION_STATUS.FULFILLED;

export const getFulfilledOrders = createSelector(
  [getAddresses, getOrderCoupons, getOrderProducts, getOrders, getStores],
  (addresses, orderCoupons, orderProducts, orders, stores) => {
    const mapOrderCoupons = (orderCouponId) =>
      orderCoupons[orderCouponId] || {};

    const mapOrderProducts = (orderProductId) =>
      orderProducts[orderProductId] || {};

    const mapFulfilledOrders = ({
      addressId,
      orderCouponIds = [],
      orderProductIds = [],
      storeId,
      ...order
    }) =>
      Object.assign(
        {
          address: addresses[addressId] || {},
          orderCoupons: orderCouponIds.map(mapOrderCoupons),
          orderProducts: orderProductIds.map(mapOrderProducts),
          store: stores[storeId] || {},
        },
        order
      );

    return Object.values(orders)
      .filter(filterByFulfilled)
      .map(mapFulfilledOrders);
  }
);

const getCarryoutAddressString = ({ city = "", regionCode = "" } = {}) =>
  `${city}, ${regionCode}`;

export const getDeliveryAddressString = (address) => getAddressString(address);

export const getDeliveryAddressWithComplexString = (address) =>
  [getAddressString, formatComplexLine]
    .map((selector) => selector(address))
    .join(" ");

const getAddressStringForServiceMethod = (address, serviceMethod) =>
  (isDelivery(serviceMethod)
    ? getDeliveryAddressString
    : getCarryoutAddressString)(address);

export const getOrderHistory = createSelector(
  [getFulfilledOrders, getCoupons, getBases, getSizes, getToppings],
  (orders, coupons, bases, sizes, toppings) => {
    const mapCoupons = ({ couponCode }, index) => {
      const { couponName } = coupons[couponCode] || {};

      return {
        id: `${couponCode}${index}`,
        name: couponName ? `${couponName} (${couponCode})` : couponCode,
        options: [],
      };
    };

    const mapProducts = ({
      baseId,
      itemQuantity = "1",
      parts = {},
      sizeId,
      orderProductId,
    }) => {
      const { baseName } = bases[baseId] || {};
      const { sizeName } = sizes[sizeId] || {};

      const getProductName = (name) =>
        [sizeName, baseName, name].filter(exists).join(" ");

      const reduceParts = ([part, { name, options }]) => {
        const getTopping = (code) =>
          Object.values(toppings).find(
            ({ toppingCode }) => toppingCode === code
          ) || {};
        const getPartsOptions = ({ code, weight }) => {
          const { toppingName } = getTopping(code);
          return {
            code,
            name: toppingName,
            weight,
          };
        };

        return {
          part,
          name: getProductName(name),
          options: options.map(getPartsOptions),
        };
      };
      const [{ name: firstProductName }, ...products] = Object.values(parts);
      const isSameProduct = products.every(
        ({ name }) => name === firstProductName
      );
      const hasCustomization = Object.values(parts).some(
        ({ options = [] }) => options.length
      );

      return {
        name: isSameProduct ? getProductName(firstProductName) : "",
        id: orderProductId,
        quantity: itemQuantity,
        options:
          isSameProduct && !hasCustomization
            ? []
            : Object.entries(parts).map(reduceParts, []),
      };
    };

    const reduceOrders = (
      all,
      {
        address,
        orderDateTime,
        orderId,
        orderCoupons = [],
        orderProducts = [],
        serviceMethod = SERVICE_METHOD.CARRYOUT,
        store,
        storeOrderId,
        ...order
      }
    ) => {
      const {
        addressDescription,
        storeId,
        storeName,
        isCallCenterEnabled = true,
      } = store || {};

      return Object.assign(all, {
        [storeOrderId]: {
          deliveryAddress:
            isDelivery(serviceMethod) &&
            getDeliveryAddressWithComplexString(address),
          isCallCenterEnabled,
          orderDateTime: formatDateTime({
            dateTime: new Date(orderDateTime),
            timeZoneAware: false,
          }),
          orderCoupons: orderCoupons.map(mapCoupons),
          orderProducts: orderProducts.map(mapProducts),
          orderId,
          orderLink: getSelfLink(order),
          serviceMethod,
          storeAddress: addressDescription,
          storeId,
          storeOrderId,
          storeName,
        },
      });
    };

    return orders.reduce(reduceOrders, {});
  }
);

const getFulfilledOrdersByServiceMethod = defaultMemoize((serviceMethodQuery) =>
  createSelector([getFulfilledOrders], (orders) =>
    orders.filter(({ serviceMethod }) => serviceMethod === serviceMethodQuery)
  )
);

const getOrderLocations = (
  serviceMethod = SERVICE_METHOD.CARRYOUT,
  resultLimit
) =>
  createSelector(
    [getFulfilledOrdersByServiceMethod(serviceMethod), getMarketConfigs],
    (orders, { IS_FILTER_CALL_CENTER_ENABLED }) =>
      orders
        .slice(0, resultLimit || orders.length)
        .map(({ address, orderDateTime, orderId, store }) => {
          const {
            addressDescription,
            isCallCenterEnabled,
            isOnlineNow,
            isOpen,
            phone,
            storeId,
            storeName,
          } = store;

          const complexDescription = formatComplexLine(address);
          const customerAddress = getAddressStringForServiceMethod(
            address,
            serviceMethod
          );
          const isCallCenterDisabled =
            IS_FILTER_CALL_CENTER_ENABLED && !isCallCenterEnabled;
          return {
            addressDescription,
            complexDescription,
            customerAddress,
            customerAddressNickname: isDelivery(serviceMethod)
              ? address.addressNickname
              : "",
            disabled: !isOnlineNow || isCallCenterDisabled,
            isOnlineNow,
            isOpen,
            onlineStatus: isOnlineNow
              ? ONLINE_STATUS.ONLINE
              : ONLINE_STATUS.OFFLINE,
            orderDateTime: formatDateTime({
              dateTime: new Date(orderDateTime),
              timeZoneAware: false,
            }),
            orderId,
            phone,
            storeId,
            storeName,
            storeStatus: isOpen ? STORE_STATUS.OPEN : STORE_STATUS.CLOSED,
          };
        })
  );

export const getCarryoutOrderLocations = createSelector(
  [getOrderLocations(SERVICE_METHOD.CARRYOUT)],
  getResult
);

export const getDeliveryOrderLocations = createSelector(
  [getOrderLocations(SERVICE_METHOD.DELIVERY)],
  getResult
);

export const getOrder = (orderId = "") =>
  createSelector([getOrders], (orders) => orders[orderId] || {});

export const getCurrentOrder = createSelector(
  [getOrders],
  (orders) =>
    Object.values(orders)
      .sort(sortByProp("orderDate"))
      .find(filterByUnfulfilled) || {}
);

export const getCurrentOrderId = createSelector(
  [getCurrentOrder],
  ({ orderId = "" }) => orderId
);

const getStoreId = ({ storeId = "" }) => storeId;

export const getOrderStoreId = (orderId) =>
  createSelector([getOrder(orderId)], getStoreId);

export const getCurrentOrderStoreId = createSelector(
  [getCurrentOrder],
  getStoreId
);

const getFromStateById = (collection = {}, id = "") => collection[id] || {};

const getStore = getFromStateById;

export const getOrderStore = (orderId) =>
  createSelector([getStores, getOrderStoreId(orderId)], getStore);

export const getCurrentOrderStore = createSelector(
  [getStores, getCurrentOrderStoreId],
  getStore
);

const getAddressId = ({ addressId = "" }) => addressId;

export const getOrderAddressId = (orderId) =>
  createSelector([getOrder(orderId)], getAddressId);

export const getCurrentOrderAddressId = createSelector(
  [getCurrentOrder],
  getAddressId
);

const getAddress = getFromStateById;

export const getOrderAddress = (orderId) =>
  createSelector([getAddresses, getOrderAddressId(orderId)], getAddress);

export const getCurrentOrderAddress = createSelector(
  [getAddresses, getCurrentOrderAddressId],
  getAddress
);

export const getCurrentAddressLine1 = createSelector(
  [getCurrentOrderAddressId, getState],
  (addressId, state) => getAddressLine1(addressId)(state)
);

export const getCurrentStreetAddress = createSelector(
  [getCurrentOrderAddressId, getState],
  (addressId, state) => getStreetAddress(addressId)(state)
);

export const getCurrentAddressLine2 = createSelector(
  [getCurrentOrderAddressId, getState],
  (addressId, state) => getAddressLine2(addressId)(state)
);

export const getCurrentDeliveryInstructions = createSelector(
  [getCurrentOrderAddressId, getState],
  (addressId, state) => getDeliveryInstructions(addressId)(state)
);

export const getOrderStarted = createSelector(
  [getCurrentOrderId],
  (orderId) => !!orderId
);

const getCurrentOrderAmountBreakDown = createSelector(
  [getCurrentOrder],
  ({ amountBreakDown }) => amountBreakDown || {}
);

export const getCurrentOrderFoodAndBeverage = createSelector(
  [getCurrentOrderAmountBreakDown],
  ({ foodAndBeverage = 0 }) => foodAndBeverage
);

export const getCurrentOrderDeliveryFee = createSelector(
  [getCurrentOrderAmountBreakDown],
  ({ deliveryFee = null }) => deliveryFee
);

export const getCurrentOrderTax = createSelector(
  [getCurrentOrderAmountBreakDown],
  ({ tax = 0 }) => tax
);

export const getCurrentOrderTotal = createSelector(
  [getCurrentOrderAmountBreakDown],
  ({ total = 0 }) => total
);

export const getCurrentOrderSavings = createSelector(
  [getCurrentOrderAmountBreakDown],
  ({ savings = 0 }) => savings
);

export const getCurrentServiceMethod = createSelector(
  [getCurrentOrder, getAvailableServiceMethods],
  ({ serviceMethod }, availableServiceMethods) =>
    serviceMethod || availableServiceMethods[0]
);

export const getCurrentOrderTiming = createSelector(
  [getCurrentOrder],
  ({ orderTiming = ORDER_TIMING.NOW }) => orderTiming
);

export const getIsFutureOrder = createSelector(
  [getCurrentOrder],
  ({ orderTiming = ORDER_TIMING.NOW }) => orderTiming === ORDER_TIMING.LATER
);

export const getCurrentOrderDate = createSelector(
  [getCurrentOrder],
  ({ date }) => date
);

export const getCurrentOrderTime = createSelector(
  [getCurrentOrder],
  ({ time = "" }) => time
);

export const getValidOrderTiming = createSelector(
  [getCurrentOrderTiming, getCurrentOrderTime],
  (orderTiming = ORDER_TIMING.NOW, orderTime = "") =>
    orderTiming === ORDER_TIMING.NOW ||
    (orderTiming === ORDER_TIMING.LATER && !!orderTime)
);

export const getCurrentOrderTimeList = createSelector(
  [getCurrentOrder],
  ({ timeList = [] }) => timeList
);

export const getCurrentOrderFutureTime = createSelector(
  [getCurrentOrder],
  ({ futureTime = "" }) => futureTime
);

export const getCurrentOrderProducts = createSelector(
  [getCurrentOrderId, getOrderProducts],
  (currentOrderId, orderProducts) =>
    Object.values(orderProducts)
      .filter(({ orderId }) => orderId === currentOrderId)
      .reduce(
        (all, { orderProductId, ...order }) =>
          Object.assign(all, {
            [orderProductId]: Object.assign({ orderProductId }, order),
          }),
        {}
      )
);

export const getOrderProductMappings = createSelector(
  [
    getSizes,
    getBases,
    getProducts,
    getToppings,
    getSides,
    getOrderProducts,
    getParts,
    getPartOptions,
  ],
  (
    sizes,
    bases,
    products,
    toppings,
    sides,
    orderProducts,
    parts,
    partOptions
  ) => {
    const reduceAreaProducts = (all, { area, productName }) =>
      Object.assign(all, {
        [area]: productName,
      });

    const reducePartOptions = (
      all,
      { optionId, optionType, optionWeight, partOptionId, ...partOption }
    ) =>
      Object.assign(all, {
        [partOptionId]: Object.assign(
          {
            optionId,
            optionType,
            optionWeight,
            partOptionId,
          },
          partOption,
          optionType === OPTION.TOPPING
            ? toppings[optionId] || {}
            : sides[optionId] || {}
        ),
      });

    const reduceOrderProductParts = (
      all,
      { productId, partId, partOptionIds, ...part }
    ) =>
      Object.assign(all, {
        [partId]: Object.assign(
          {
            product: products[productId] || {},
            partId,
            partOptions: Object.values(partOptions)
              .filter(({ partOptionId }) =>
                partOptionIds.includes(partOptionId)
              )
              .reduce(reducePartOptions, {}),
          },
          part
        ),
      });

    return Object.values(orderProducts).map(
      ({ sizeId, baseId, partIds, ...orderProduct }) =>
        Object.assign({}, orderProduct, {
          areas: partIds
            .map((partId) => parts[partId])
            .reduce(reduceAreaProducts, {}),
          base: bases[baseId] || {},
          parts: Object.values(parts)
            .filter(({ partId }) => partIds.includes(partId))
            .reduce(reduceOrderProductParts, {}),
          size: sizes[sizeId],
        })
    );
  }
);

export const getActiveOrderProduct = createSelector(
  [getOrderProducts],
  (orderProducts) =>
    Object.values(orderProducts).find(({ active }) => active) || {}
);

export const getActiveOrderProductId = createSelector(
  [getActiveOrderProduct],
  ({ orderProductId = "" }) => orderProductId
);

export const getActiveOrderProductPartIds = createSelector(
  [getActiveOrderProduct],
  ({ partIds = [] }) => partIds
);

export const getActiveOrderProductBaseId = createSelector(
  [getActiveOrderProduct],
  ({ baseId = "" } = {}) => baseId
);

export const getActiveOrderProductParts = createSelector(
  [getActiveOrderProductPartIds, getParts],
  (partIds, parts) => partIds.map((partId) => parts[partId])
);

export const getActiveOrderProductPartOptions = createSelector(
  [getActiveOrderProductParts, getPartOptions],
  (parts, partOptions) =>
    parts.reduce(
      (allParts, { area, partOptionIds }) =>
        Object.assign(allParts, {
          [area]: Object.assign(
            {},
            allParts[area] || {},
            partOptionIds.reduce(
              (allPartOptions, partOptionId) =>
                Object.assign({}, allPartOptions, {
                  [partOptionId]: partOptions[partOptionId],
                }),
              {}
            )
          ),
        }),
      {}
    )
);

export const getActiveOrderProductProductIds = createSelector(
  [getActiveOrderProductParts],
  (parts) =>
    parts
      .map(({ area, productId }) => ({
        [area]: productId,
      }))
      .reduce((all, area) => Object.assign(all, area), {}) || {}
);

export const getActiveOrderProductOptions = createSelector(
  [
    getActiveOrderProductProductIds,
    getActiveOrderProductPartOptions,
    getProducts,
    getToppings,
    getWeightedOptions,
  ],
  (areas, partOptions, products, toppings, weightedOptions) =>
    Object.entries(areas)
      .map(([area, productId]) => {
        const currentPartOptions = Object.values(partOptions[area]);

        const { defaultOptions = {} } = products[productId] || {};

        const defaultPartOptions = Object.entries(defaultOptions)
          .map(([weightedOptionId, optionWeight]) => {
            const { optionId } = weightedOptions[weightedOptionId];

            const optionType = toppings[optionId]
              ? OPTION.TOPPING
              : OPTION.SIDE;

            return {
              optionId,
              optionType,
              optionWeight,
            };
          })
          .filter(
            ({ optionId: defaultOptionId }) =>
              !currentPartOptions
                .map(({ optionId }) => optionId)
                .includes(defaultOptionId)
          );

        return {
          [area]: defaultPartOptions
            .concat(currentPartOptions)
            .filter(
              ({ optionWeight }) => parseFloat(optionWeight) > WEIGHT[0].value
            )
            .reduce(
              (all, { optionId, optionWeight }) =>
                Object.assign(all, {
                  [optionId]: optionWeight,
                }),
              {}
            ),
        };
      })
      .reduce((all, area) => Object.assign(all, area), {})
);

export const getActiveOrderProductOptionIds = createSelector(
  [getActiveOrderProductOptions],
  (options) =>
    Object.keys(
      Object.values(options).reduce(
        (all, option) => Object.assign(all, option),
        {}
      )
    )
);

export const getActiveOrderProductAvailableOptionsByArea = createSelector(
  [
    getActiveArea,
    getActiveOrderProductProductIds,
    getProducts,
    getWeightedOptions,
  ],
  (activeArea, areas, products, weightedOptions) => {
    const isWhole = Object.keys(areas).length === 1;
    const productIds = [];

    if (isWhole)
      Object.values(areas).forEach((productId) => productIds.push(productId));
    else productIds.push(areas[activeArea]);

    const allWeightedOptionIds = [];

    productIds.forEach((productId) => {
      if (products[productId]) {
        const { weightedOptionIds } = products[productId];

        weightedOptionIds.forEach((weightedOptionId) =>
          allWeightedOptionIds.push(weightedOptionId)
        );
      }
    });

    return Array.from(new Set(allWeightedOptionIds))
      .map((weightedOptionId) => weightedOptions[weightedOptionId])
      .reduce(
        (all, { optionId, weights }) =>
          Object.assign(all, {
            [optionId]: weights,
          }),
        {}
      );
  }
);

export const getActiveOrderProductAvailableOptions = createSelector(
  [getActiveOrderProductProductIds, getProducts, getWeightedOptions],
  (areas, products, weightedOptions) => {
    const productIds = [];

    Object.values(areas).forEach((productId) => productIds.push(productId));

    const allWeightedOptionIds = [];

    productIds.forEach((productId) => {
      if (products[productId]) {
        const { weightedOptionIds } = products[productId];

        weightedOptionIds.forEach((weightedOptionId) =>
          allWeightedOptionIds.push(weightedOptionId)
        );
      }
    });

    return Array.from(new Set(allWeightedOptionIds))
      .map((weightedOptionId) => weightedOptions[weightedOptionId])
      .reduce(
        (all, { optionId, weights }) =>
          Object.assign(all, {
            [optionId]: weights,
          }),
        {}
      );
  }
);

export const getActiveOrderProductAvailableSizes = createSelector(
  [getProducts, getActiveOrderProduct, getSizes],
  (products, activeOrderProduct, sizes) => {
    const activeOrderProductCode =
      activeOrderProduct.parts?.[defaultArea]?.productCode ?? [];
    const activeOrderProductSizeIds =
      Object.values(products).find(
        (product) => product.productCode === activeOrderProductCode
      )?.sizeIds ?? [];

    return Object.values(sizes).filter((size) =>
      activeOrderProductSizeIds.includes(size.sizeCode)
    );
  }
);

export const getActiveOrderProductAvailableSizesByBaseId = createSelector(
  [getActiveOrderProduct, getProducts],
  (activeOrderProduct, products) => {
    const activeOrderProductProductCode =
      activeOrderProduct.parts?.[AREAS.WHOLE]?.productCode;

    return products?.[activeOrderProductProductCode]?.sizesByBaseId ?? {};
  }
);

export const getActiveOrderProductAvailableSizesByBase = createSelector(
  [getActiveOrderProduct, getProducts],
  (activeOrderProduct, products) => {
    const activeOrderProductProductCode =
      activeOrderProduct.parts?.[AREAS.WHOLE]?.productCode;

    return (
      products?.[activeOrderProductProductCode]?.sizesByBaseId?.[
        activeOrderProduct?.baseId
      ] ?? []
    );
  }
);

export const getActiveOrderProductAvailableBasesBySize = createSelector(
  [getActiveOrderProduct, getProducts],
  (activeOrderProduct, products) => {
    const activeOrderProductProductCode =
      activeOrderProduct.parts?.[AREAS.WHOLE]?.productCode;

    return (
      products?.[activeOrderProductProductCode]?.basesBySizeId?.[
        activeOrderProduct?.sizeId
      ] ?? []
    );
  }
);

export const getActiveOrderProductSizeId = createSelector(
  [getActiveOrderProduct],
  ({ sizeId = "" }) => sizeId
);

export const getCurrentOrderCouponIds = createSelector(
  [getCurrentOrder],
  ({ orderCouponIds = [] }) => orderCouponIds
);

export const getCurrentOrderCoupons = createSelector(
  [getCurrentOrderCouponIds, getOrderCoupons, getCoupons],
  (orderCouponIds, orderCoupons, coupons) =>
    orderCouponIds.reduce((all, orderCouponId) => {
      const orderCoupon = orderCoupons[orderCouponId] || {};
      const {
        couponCode = "",
        name: couponDescription = "",
        description: couponName = "",
      } = orderCoupon;

      const { productGroups } = coupons[couponCode] || {};

      return Object.assign(all, {
        [orderCouponId]: Object.assign({}, orderCoupon, {
          couponCode,
          couponDescription,
          couponName,
          productGroups,
        }),
      });
    }, {})
);

const getRemovedIds = ({ state, id, orderId, orderIds }) =>
  Object.values(state)
    .filter((item) => !orderIds.includes(item[id]) && item.orderId === orderId)
    .map((item) => item[id]);

export const getRemovedOrderProductIds = (orderIds = []) =>
  createSelector(
    [getCurrentOrderProducts, getCurrentOrderId],
    (state, orderId) =>
      getRemovedIds({
        state,
        id: "orderProductId",
        orderId,
        orderIds,
      })
  );

export const getRemovedOrderCouponIds = (orderIds = []) =>
  createSelector(
    [getCurrentOrderCoupons, getCurrentOrderId],
    (state, orderId) =>
      getRemovedIds({ state, id: "orderCouponId", orderId, orderIds })
  );

export const getExtraCheeseAvailableSlots = createSelector(
  getCurrentOrderProducts,
  getCurrentOrderCoupons,
  getCheeseToppings,
  getState,
  // TODO: fix
  // eslint-disable-next-line no-unused-vars
  (orderProducts, orderCoupons, [cheeseToppingCode] = [], state) => {
    const extraCheeseCoupons = Object.values(orderCoupons)
      .map((coupon) => ({
        ...getCouponById(state, coupon.couponCode),
        ...coupon,
      }))
      .filter(({ auto2C }) => auto2C);

    if (!extraCheeseCoupons.length) return [];
    const extraCheeseSlots = extraCheeseCoupons
      .map(({ productGroups = [] }) =>
        productGroups
          .filter(
            ({
              Default: { CategoryCode = "", NeedsCustomization = false } = {},
            }) => CategoryCode === PIZZA_CATEGORY && NeedsCustomization
          )
          .map(({ ProductCodes = [], RequiredQty = 1 }) =>
            Array.from(new Array(RequiredQty)).map(() => ProductCodes)
          )
          .flat()
      )
      .flat()
      .sort((a, z) => a.length - z.length);

    const orderVariants = Object.values(orderProducts)
      .filter(({ parts = {} }) =>
        Object.values(parts).every(({ options = [] }) =>
          options.some(
            ({ code = "", weight = 1 }) => code === "C" && weight === 1.5
          )
        )
      )
      .map(({ baseId, itemQuantity = 0, sizeCode, parts = { "1/1": {} } }) => {
        const productCodes = Object.values(parts)
          .map(({ productCode = "" } = {}) => productCode)
          .filter(Boolean);
        const variantCodes = productCodes
          .map((productCode) =>
            getVariantCodeFromProduct({
              sizeCode,
              baseCode: baseId,
              productCode,
            })(state)
          )
          .filter(Boolean);
        return Array.from(new Array(itemQuantity)).map(() => variantCodes);
      })
      .flat();

    return orderVariants.reduce((availableExtraCheeseSlots, orderVariant) => {
      const index = availableExtraCheeseSlots.findIndex((availableVariants) =>
        orderVariant.every((variant) => availableVariants.includes(variant))
      );
      if (index >= 0) availableExtraCheeseSlots.splice(index, 1);
      return availableExtraCheeseSlots;
    }, extraCheeseSlots);
  }
);

export const getProductQualifiesForExtraCheese = ({
  base = "",
  parts = {},
  sizeCode = "",
}) =>
  createSelector(
    getExtraCheeseAvailableSlots,
    getCheeseToppings,
    getState,
    (availableExtraCheeseSlots = [], [cheeseToppingCode] = [], state) => {
      if (
        !availableExtraCheeseSlots.length ||
        Object.values(parts).some(({ options = [] }) =>
          options.some(({ code }) => code === cheeseToppingCode)
        )
      )
        return false;

      const variantCodes = Object.values(parts).map(({ productCode }) =>
        getVariantCodeFromProduct({
          sizeCode,
          baseCode: base,
          productCode,
        })(state)
      );

      return availableExtraCheeseSlots.some((availableExtraCheeseVariantSlot) =>
        variantCodes.every((variantCode) =>
          availableExtraCheeseVariantSlot.includes(variantCode)
        )
      );
    }
  );

export default getOrders;
